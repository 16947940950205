<template>
  <div class="card-dash-rent">
    <Molded>
      <LoadingCard v-if="loading" :totalLine="7" :height="207" />
      <div v-else>
        <b-row>
          <b-col sm="8">
            <span class="title title-dash-rent">
              {{ card.title }}
            </span>
          </b-col>
          <b-col sm="4">
            <div class="text-right">
              <small class="title title-period"> {{ filterTitle }}</small>
            </div>
          </b-col>
        </b-row>
        <div>
          <div :class="['title', 'value-dash', card.iconColor]">
            <HideShow :iconSize="18">
              {{ card.value | currency }}
            </HideShow>
          </div>
        </div>
        <ProgressBar :bgColor="card.value == 0 ? '#EEEEEE' : card.bgColor"
          :bColor="card.value == 0 ? '#EEEEEE' : card.bColor" size="10" :value="card.received"
          :max="card.maxProgress" />
        <div class="div-total-dash">
          <b-row>
            <b-col sm="1">
              <i class="fa-solid fa-user" :class="card.iconColor"></i>
            </b-col>
            <b-col sm="10">
              <span class="title">
                <span>{{ card.clients }}</span>
                <span class="title-client" v-if="card.clients <= 1">Destinatário</span>
                <span class="title-client" v-else>Destinatários</span>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="1">
              <i class="fa-solid fa-money-bill-transfer" :class="card.iconColorDone"></i>
            </b-col>
            <b-col sm="9">
              <HideShow :iconSize="15">
                <span class="title" :class="card.iconColorDone">{{ card.received | currency }}</span>
                <span :class="card.iconColorDone"> /{{ card.title == "Contas a Pagar" ? 'Pago' : 'Recebido' }}</span>
              </HideShow>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="1">
              <i class="fa-solid fa-money-bill-transfer" :class="card.iconColor"></i>
            </b-col>
            <b-col sm="9">
              <HideShow :iconSize="15">
                <span class="title" :class="card.iconColor">{{ card.pending | currency }}</span>
                <span :class="card.iconColor"> /{{ card.title == "Contas a Pagar" ? 'A Pagar' : 'A Receber' }}</span>
              </HideShow>
            </b-col>
          </b-row>
        </div>
      </div>
    </Molded>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ProgressBar from "@nixweb/nixloc-ui/src/component/shared/ProgressBar";
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";
import LoadingCard from "@nixweb/nixloc-ui/src/component/shared/LoadingCard";

export default {
  name: "FinancialCard",
  components: { Molded, ProgressBar, HideShow, LoadingCard },
  props: {
    card: Object,
    filterTitle: String,
    loading: Boolean
  },
};
</script>
<style scoped>
.card-dash-rent {
  margin-bottom: 10px;
}

.title-dash-rent {
  font-size: 16px;
}

.value-dash {
  font-weight: 700 !important;
  font-size: 22px;
  height: 50px;
}

.div-total-dash {
  margin-top: 30px;
}

.receive {
  color: #2c059e;
}

.received {
  color: #009183;
}

.pay {
  color: red;
}

.paid {
  color: #834140;
}

.warning {
  color: orange;
}

.title-client {
  margin-left: 10px;
}

.title-period {
  color: rgb(102, 101, 101);
  font-size: 13px;
}
</style>
