<template>
  <div class="container-main">
    <div class="container-icon side-by-side">
      <i class="fa-duotone fa-solid fa-filters title"></i>
    </div>
    <div class="container-select side-by-side">
      <SelectStatic :initialValue="{ content: 'Hoje', id: 'today' }" :data="options" v-model="filter" />
    </div>
    <div class="container-icon icon-eye side-by-side">
      <i class="fa-solid fa-eye fa-eye icon-filter-dash title" v-if="isVisible" @click="handleIsVisible(false)"></i>
      <i class="fa-solid fa-eye-slash icon-filter-dash title" v-else @click="handleIsVisible(true)"></i>
    </div>
    <div class="container-icon icon-refresh side-by-side">
      <i v-if="!isLoading" class="fa-sharp fa-solid fa-arrows-rotate icon-filter-dash title" @click="refresh"></i>
      <i v-else class="fa-sharp fa-solid fa-arrows-rotate loading icon-filter-dash title"></i>
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "SelectFilter",
  components: {
    Button,
    SelectStatic,
  },
  data() {
    return {
      filter: {},
      options: [
        { content: "Hoje", id: "today" },
        { content: "Semana Atual", id: "week" },
        { content: "Mês Atual", id: "month" },
        { content: "Ano Atual", id: "year" },
      ],
      isVisible: false,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent"]),
    handleIsVisible(value) {
      this.isVisible = value;
      this.addEvent({ name: "hideShow", data: value });
    },
    refresh() {
      this.isLoading = true;
      this.addEvent({ name: "filterDashboard", data: this.filter });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  },
  watch: {
    filter: {
      handler() {
        this.addEvent({ name: "filterDashboard", data: this.filter });
      },
      deep: true,
    },
    event: {
      handler(event) {
        if (event.name == "filterInitialDashboard") {
          if (event.data == "today") this.filter = this.options[0];
          if (event.data == "week") this.filter = this.options[1];
          if (event.data == "month") this.filter = this.options[2];
          if (event.data == "year") this.filter = this.options[3];
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.container-main {
  margin-top: 14px;
  margin-bottom: -20px;
  margin-left: 10px;
}

.container-icon {
  margin-right: 20px;
}

.container-select {
  width: 250px;
}

.icon-filter {
  margin-top: 12px;
  font-size: 16px;
}

.icon-eye {
  margin-left: 20px;
  font-size: 20px;
  cursor: pointer;
}

.icon-refresh {
  margin-left: -10px;
  font-size: 20px;
  cursor: pointer;
}

.refresh-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading {
  animation: spin 0.8s linear infinite;
}

.icon-filter-dash {
  font-size: 19px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>