<template>
    <div class="rent-main">
        <div class="loading-card"></div>
        <b-row>
            <b-col sm="9">
                <div class="div-title text-left">
                    <br>
                    <span class="title-main">Locação</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3" v-for="(card, index) in cards" :key="index">
                <StatusCard :loading="loading" :titleFilter="filter.content" :title="card.title" :value="card.value"
                    :valueClass="card.valueClass" :bgColor="card.bgColor" :valueProgress="card.valueProgress"
                    :maxProgress="card.maxProgress" :clients="card.clients" :rentals="card.rentals" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
                <div class="card-dash-rent">
                    <Molded>
                        <LoadingCard v-if="loading" :totalLine="5" :height="176" />
                        <div v-else>
                            <div>
                                <b-row>
                                    <b-col sm="6">
                                        <span class="title title-dash-rent">
                                            Ranking
                                        </span>
                                    </b-col>
                                    <b-col sm="6">
                                        <div class="text-right">
                                            <small class="title title-period"> {{ filter.content }}</small>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <ScrollBar :minHeight="145" :maxHeight="145">
                                <div v-for="(item, index) in ranking" :key="item.id">

                                    <div class="side-by-side">
                                        <img :class="{ 'gold': index == 0, 'silver': index == 1, 'bronze': index == 2 }"
                                            :src="'https://espaco.blob.core.windows.net/nixloc-photo-user/' + item.photo"
                                            class="photo" />
                                        <span class="title title-ranking">{{ truncatedText(item.name) }}</span>
                                    </div>

                                    <div class="text-right title-total-dash accepted side-by-side">
                                        <HideShow :iconSize="13">
                                            {{ item.value | currency }}
                                        </HideShow>
                                    </div>

                                </div>
                                <div v-if="ranking.length == 0">
                                    <small> Nenhum registro encontrado!</small>
                                </div>
                            </ScrollBar>
                        </div>
                    </Molded>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";
import LoadingCard from "@nixweb/nixloc-ui/src/component/shared/LoadingCard";

import StatusCard from './StatusCard.vue';

import { mapActions, mapGetters } from "vuex";

export default {
    name: "PanelRent",
    components: { StatusCard, Molded, ScrollBar, HideShow, LoadingCard },
    data() {
        return {
            urlGet: "/api/v1/rental/rent/dashboard-total-rent",
            urlRanking: "/api/v1/rental/rent/dashboard-ranking-collaborator",
            filter: { content: "Hoje", id: "today" },
            loading: true,
            cards: [{}, {}, {}],
            ranking: [{}],
        };
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        truncatedText(name) {
            return name.length > 9 ? name.slice(0, 9) + '...' : name;
        },
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGet,
                obj: { filter: this.filter.id },
            };
            this.getApi(params).then((response) => {
                this.cards = response.content;
                this.loading = false;
            });
        },
        getRanking() {
            let params = {
                url: this.urlRanking,
                obj: { filter: this.filter.id },
            };
            this.getApi(params).then((response) => {
                this.ranking = response.content;
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "filterDashboard") {
                    this.filter = event.data;
                    this.getAll();
                    this.getRanking();
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.rent-main {
    margin-left: -20px;
}

.title-main {
    font-size: 20px;
}

.div-title {
    font-size: 15px;
    margin-bottom: 10px;
}

.photo {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-bottom: 5px;
}

.gold {
    border: 2px solid #FFD700
}

.silver {
    border: 2px solid #C0C0C0
}

.bronze {
    border: 2px solid #CD7F32
}

.title-ranking {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.title-total-dash {
    font-weight: 500;
    font-size: 13px;
}

.accepted {
    color: #38A169;
}

.title-period {
    color: rgb(102, 101, 101);
    font-size: 13px;
}
</style>