<template>
  <div class="card-dash-rent">
    <Molded>
      <LoadingCard v-if="loading" :totalLine="5" :height="176" />
      <div v-else>
        <b-row>
          <b-col sm="6">
            <span class="title title-dash-rent">
              {{ title }}
            </span>
          </b-col>
          <b-col sm="6">
            <div class="text-right">
              <small class="title title-period"> {{ titleFilter }}</small>
            </div>
          </b-col>
        </b-row>
        <div :class="`value-dash-rent ${valueClass}`">
          <HideShow :iconSize="18">{{ value | currency }}</HideShow>
        </div>
        <ProgressBar :bgColor="value == 0 ? '#EEEEEE' : bgColor" bColor="#91D5FF" size="10" :value="value"
          :max="maxProgress" />
        <div class="div-total-dash">
          <b-row>
            <b-col sm="1">
              <i :class="`fa-solid fa-user ${valueClass}`"></i>
            </b-col>
            <b-col sm="10">
              <span class="title">{{ clients }}
                <span v-if="clients == 1">Cliente</span>
                <span v-else>Clientes</span>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="1">
              <i :class="`fa-solid fa-right-left ${valueClass}`"></i>
            </b-col>
            <b-col sm="10">
              <span class="title">{{ rentals }}
                <span v-if="rentals == 1">Locação</span>
                <span v-else>Locações</span>
              </span>
            </b-col>
          </b-row>
        </div>
      </div>
    </Molded>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ProgressBar from "@nixweb/nixloc-ui/src/component/shared/ProgressBar";
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";
import LoadingCard from "@nixweb/nixloc-ui/src/component/shared/LoadingCard";

export default {
  name: "RentCard",
  components: { Molded, ProgressBar, HideShow, LoadingCard },
  props: {
    title: String,
    titleFilter: String,
    value: String,
    valueClass: String,
    bgColor: String,
    valueProgress: Number,
    maxProgress: Number,
    clients: Number,
    rentals: Number,
    loading: Boolean,
  },
};
</script>

<style scoped>
.rent-main {
  margin-left: -18px;
}

.title-main {
  font-size: 20px;
}

.div-title {
  font-size: 15px;
  margin-bottom: 10px;
}

.title-dash-rent {
  font-size: 16px;
}

.value-dash-rent {
  font-weight: bold;
  font-size: 22px;
  height: 50px;
}

.accepted {
  color: #38A169;
}

.progress-rent-dash {
  color: #91D5FF;
}

.rejected {
  color: #FF5454;
}

.div-total-dash {
  margin-top: 30px;
}

.photo {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.card-dash-rent {
  margin-bottom: 10px;
}

.title-total-dash {
  font-weight: 500;
}

.title-period {
  color: rgb(102, 101, 101);
  font-size: 13px;
}
</style>