<template>
    <div class="rent-main">
        <b-row>
            <b-col sm="9">
                <div class="div-title text-left">
                    <br />
                    <span class="title-main">Financeiro</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" v-for="(card, index) in financialCards" :key="index">
                <FinancialCard :loading="loading" :card="card" :filterTitle="filter.content" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4">
                <div class="card-dash-rent">
                    <Molded>
                        <LoadingCard v-if="loading" :totalLine="7" :height="207" />
                        <div v-else>
                            <div>
                                <span class="title title-dash-rent">Saldo Bancário Atual</span>
                            </div>
                            <div>
                                <HideShow :iconSize="18">
                                    <span class="title-total-account">
                                        <span v-if="totalAccounts > 0" class="receive">
                                            {{ totalAccounts | currency }}
                                        </span>
                                        <span v-if="totalAccounts < 0" class="pay">
                                            {{ totalAccounts | currency }}
                                        </span>
                                    </span>
                                </HideShow>
                            </div>
                            <ScrollBar :minHeight="140" :maxHeight="140">
                                <div v-for="item in bankAccount" :key="item">
                                    <b-row>
                                        <b-col xs="12" sm="12" md="12" lg="12" xl="6">
                                            <img :src="'https://espaco.blob.core.windows.net/nixloc-bank-logo/' + item.urlLogo"
                                                class="photo" />
                                            <span class="title title-ranking">{{ truncatedText(item.name) }}</span>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="12" lg="12" xl="6">
                                            <div class="text-right">
                                                <HideShow :iconSize="13">
                                                    <span v-if="item.accountBalance > 0" class="receive">
                                                        {{ item.accountBalance | currency }}
                                                    </span>
                                                    <span v-if="item.accountBalance < 0" class="pay">
                                                        {{ item.accountBalance | currency }}
                                                    </span>
                                                </HideShow>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </ScrollBar>
                        </div>
                    </Molded>
                </div>
            </b-col>
        </b-row>
        <!--<b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="5">
                <div class="div-balance-finance">
                    <Molded>
                        <div class="text-center title-dash-rent title">
                            Gráfico Anual de Contas a Pagar e Receber
                        </div>
                        <BalanceFinance />
                    </Molded>
                </div>
            </b-col>
        </b-row> -->
    </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ProgressBar from "@nixweb/nixloc-ui/src/component/shared/ProgressBar";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";
import LoadingCard from "@nixweb/nixloc-ui/src/component/shared/LoadingCard";

import BalanceFinance from "../../../../components/modules/dashboard/finance/BalanceFinance.vue";
import FinancialCard from "./FinancialCard.vue";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "PanelRent",
    components: { Molded, ProgressBar, ScrollBar, HideShow, FinancialCard, LoadingCard, BalanceFinance },
    data() {
        return {
            urlGet: "/api/v1/finance/payable-receivable/dashboard-payable-receivable",
            urlBank: "/api/v1/finance/bank-account/dashboard-bank-account-balance",
            filter: { content: "Hoje", id: "today" },
            loading: true,
            financialCards: [{}, {}],
            bankAccount: [],
            totalAccounts: 100,
        };
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGet,
                obj: { filter: this.filter.id },
            };
            this.getApi(params).then((response) => {
                this.financialCards = response.content;
                this.loading = false;
            });
        },
        getBank() {
            this.loading = true;
            let params = {
                url: this.urlBank,
                obj: {},
            };
            this.getApi(params).then((response) => {
                this.bankAccount = response.content;
                this.totalAccounts = response.content.reduce((acc, item) => acc + item.accountBalance, 0);
            });
        },
        truncatedText(name) {
            return name.length > 13 ? name.slice(0, 13) + '...' : name;
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "filterDashboard") {
                    this.filter = event.data;
                    this.getAll();
                    this.getBank();
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.rent-main {
    margin-left: -18px;
}

.title-main {
    font-size: 20px;
}

.div-title {
    font-size: 15px;
    margin-bottom: 10px;
}

.title-dash-rent {
    font-size: 16px;
}

.value-dash-rent {
    font-weight: bold;
    font-size: 25px;
    height: 50px;
}

.receive {
    color: #2c059e;
}

.progress-rent-dash {
    color: #91d5ff;
}

.rejected {
    color: #ff5454;
}

.div-total-dash {
    margin-top: 30px;
}

.photo {
    width: 20px;
    height: 20px;
    border-radius: 50px;
}

.title-ranking {
    margin-left: 10px;
}

.card-dash-rent {
    margin-bottom: 10px;
}

.received {
    color: #009183;
}

.pay {
    color: red;
}

.paid {
    color: #834140;
}

.warning {
    color: orange;
}

.title-total-account {
    font-size: 18px;
    font-weight: 500;
}

.div-balance-finance {
    margin-top: 25px;
}
</style>