<template>
  <div>
    <Panel module="Dashboard" :showFilter="false" :showSearch="false" :showButtons="false">
      <div slot="content-main">
        <Warning />
        <div v-if="userLogged.user.userName == 'UserTemp'">
          <br>
          <Alert type="warning">
            <span>
              Você está <b> temporariamente </b> logado, e algumas funções podem não estar disponíveis!
            </span>
          </Alert>
        </div>
        <div v-else>
          <div class="container-loading">
            <div v-if="loading" class="div-loading">
              <br />
              <Loading type="line" :center="false" v-show="loading" />
            </div>
          </div>
          <div v-for="item in items" :key="item.title" v-show="!loading">
            <div>
              <Favorite v-if="item.title == 'Favorito' && item.isShow" />
              <div class="div-task-dashboard" v-if="item.title == 'Tarefa' && item.isShow">
                <PanelTask />
              </div>
            </div>
            <div v-if="item.title == 'Filtro' && item.isShow">
              <SelectFilter />
            </div>
            <PanelOperational v-if="hasRule('Operational') && isPermission('Operational') &&
              item.title == 'Operacional' &&
              item.isShow
            " />
            <PanelInvoice v-if="hasRule('Billing') && isPermission('Billing') && item.title == 'Faturamento' && item.isShow
            " />
            <div class="panel-rent"
              v-if="hasRule('Rental') && isPermission('Rental') && item.title == 'Locação' && item.isShow">

              <PanelRent />
            </div>
            <PanelBillet v-if="hasRule('Billet') && isPermission('Billet') &&
              item.title == 'Boleto Bancário' &&
              item.isShow
            " />
            <PanelSignature v-if="hasRule('Signature') && isPermission('Signature') &&
              item.title == 'Assinatura Digital' &&
              item.isShow
            " />

            <div class="panel-rent" v-if="hasRule('Finance') && isPermission('Finance') &&
              item.title == 'Financeiro' &&
              item.isShow
            ">
              <PanelPayReceive />
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <Modal :title="comunication.title" :width="800" :height="750" v-if="showModal('comunication')">
      <Comunication :comunication="comunication" />
    </Modal>
  </div>
</template>

<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import ButtonFilter from "@nixweb/nixloc-ui/src/component/forms/ButtonFilter";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Favorite from "../../../components/modules/dashboard/favorite/_Favorite.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import PanelRent from "../../../components/modules/dashboard/rent/PanelRent.vue";
import PanelInvoice from "../../../components/modules/dashboard/billing/PanelInvoice.vue";
import PanelOperational from "../../../components/modules/dashboard/operational/PanelOperational.vue";
import PanelTask from "../../../components/modules/dashboard/task/PanelTask.vue";
import PanelBillet from "../../../components/modules/dashboard/billing/PanelBillet.vue";
import PanelSignature from '../../../components/modules/dashboard/signature/PanelSignature.vue'
import PanelFinance from "../../../components/modules/dashboard/finance/PanelFinance.vue";
import PanelPayReceive from "../../../components/modules/dashboard/finance/PanelPayReceive.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import SelectFilter from "../../../components/modules/dashboard/shared/SelectFilter.vue";
import Comunication from "../../../components/modules/notifications/Comunication.vue";
import Warning from '../../../components/modules/adm/plan/Warning.vue'

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Panel,
    ButtonFilter,
    PanelOperational,
    Favorite,
    PanelRent,
    PanelFinance,
    PanelTask,
    PanelInvoice,
    PanelPayReceive,
    PanelBillet,
    SelectFilter,
    Loading,
    Modal,
    Comunication,
    Alert, PanelSignature, Warning
  },
  data() {
    return {
      urlGetById: "/api/v1/adm/user/get-by-id",
      items: [
        {
          title: "Favorito",
          icon: "fa-solid fa-star",
          module: "all",
          isShow: true,
        },
        {
          title: "Tarefa",
          icon: "fa-solid fa-check-double",
          module: "all",
          isShow: true,
        },
        {
          title: "Filtro",
          icon: "fa-solid fa-check-double",
          module: "all",
          isShow: true,
        },
        {
          title: "Operacional",
          icon: "fa-light fa-truck-loading",
          module: "Operational",
          isShow: true,
        },
        {
          title: "Faturamento",
          icon: "fa-light fa-file-invoice",
          module: "Billing",
          isShow: true,
        },
        {
          title: "Locação",
          icon: "fa-sharp fa-light fa-right-left",
          module: "Rental",
          isShow: true,
        },
        {
          title: "Boleto Bancário",
          icon: "fa-light fa-barcode",
          module: "Billet",
          isShow: true,
        },
        {
          title: "Assinatura Digital",
          icon: "fa-solid fa-signature",
          module: "Signature",
          isShow: true,
        },
      ],
      loading: false,
      comunication: {},
    };
  },
  mounted() {
    this.getById();
    this.getComunication();
    this.removeLoading(["panel"]);
  },
  computed: {
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event", "showModal"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    getById() {
      this.loading = true;
      let params = {
        url: this.urlGetById,
        obj: { userId: this.userLogged.user.id },
      };
      this.getApi(params).then((response) => {
        let self = this;
        if (response.content.dashboard != null) {
          setTimeout(function () {
            var ret = JSON.parse(response.content.dashboard);
            if (ret.order) self.items = ret.order;
            self.loading = false;
            self.addEvent({
              name: "filterInitialDashboard",
              data: ret.filter,
            });
          }, 300);
        }
        setTimeout(function () {
          self.loading = false;
        }, 300);
      });
    },
    getComunication() {
      var environment =
        process.env.VUE_APP_API_URL ==
          "https://backend-cliente-prod.azurewebsites.net/"
          ? "prod"
          : "dev";
      var BASE_URL =
        environment == "prod"
          ? "https://backend-adm-prod.azurewebsites.net/"
          : "https://backend-adm-dev.azurewebsites.net/";

      let url = `${BASE_URL}api/v1/support/comunication/get`;
      this.$http.get(url).then((response) => {
        this.comunication = response.data.content;
        let self = this;
        setTimeout(function () {
          if (self.comunication.show && self.userLogged.user.showComunication) {
            self.openModal("comunication");
          }
        }, 1000);
      });
    },
    isPermission(rule) {
      let hasPermission = this.hasPermission(rule);
      if (hasPermission) {
        if (hasPermission.includes("dashboard")) {
          return true;
        }
        return false;
      }
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateDashboard") this.getById();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.container-loading {
  margin-top: 35px;
}


.panel-rent {
  margin-left: 25px;
}

.div-task-dashboard {
  margin-top: 20px;
}

.div-loading {
  margin-bottom: 30px;
}
</style>
